<div *ngIf="!isModelToleranceForExcludedFF">
  <eclipse-analytics-duration *ngIf="portfolioAnalytics?.length"
    [portfolioAnalytics]="portfolioAnalytics"></eclipse-analytics-duration>
</div>
<div class="margin-top-10 flex justify-space-between" *ngIf="modelToleranceData.model != undefined || showModelToleranceNoModel">
  <div class="text-left">
    <span class="p-input-icon-right flex align-items-center">
      <p-radioButton label="Use Pending Values" name="barChartTypeOptionPendingValues" class="m-r-5" [ngModel]="selectedBarChartType" value="1" (onClick)="selectBarChartType(1)"></p-radioButton>
      <p-radioButton label="Use Current Values" name="barChartTypeOptionCurrentValues" class="m-r-5" [ngModel]="selectedBarChartType" value="2" (onClick)="selectBarChartType(2)"></p-radioButton>
    </span>
  </div>
  <div *ngIf="!isModelToleranceForExcludedFF" class="text-right" style="margin-left: auto"><strong>Set Aside Cash Target: </strong> <span class="text-info">{{!!modelTolSetAsideCash ? (modelTolSetAsideCash | currency:'USD') : "$0.00" }}</span></div>
  <div *ngIf="isModelToleranceForExcludedFF">
    <eclipse-analytics-duration *ngIf="portfolioAnalytics?.length"
      [portfolioAnalytics]="portfolioAnalytics">
    </eclipse-analytics-duration>
  </div>
</div>
<div class="custom-tab-container fullList-scroll" *ngIf="modelToleranceData.model != undefined || showModelToleranceNoModel">
  <p-tabView styleClass="tabs-only">
    <ng-container *ngFor="let item of modelLevels">
      <p-tabPanel [id]="item.name" tabName="{{item.name}}" [selected]="item.name === selectedTab">
        <ng-template pTemplate="header">
          <a (click)="isModelToleranceUpdatesFF ? onTabChange(item.name, item.id, true) : onTabChangeLegacy(item.name, item.id)">{{item.displayName}}</a>
        </ng-template>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
  <div id="Security">
    <eclipse-group-bar-chart></eclipse-group-bar-chart>
  </div>
</div>
<div class="clearfix"></div>
<div class="margin-top-10 flex justify-space-between" *ngIf="modelToleranceData.model != undefined || showModelToleranceNoModel">
  <button type="button" pButton name="exportToExcel" class="p-button-sm" label="Export to Excel" (click)="isModelToleranceUpdatesFF ? exportToExcel() : exportToExcelLegacy()"></button>
  <span class="p-input-icon-right flex align-items-center">
    <p-radioButton label="Percent" name="radPercent" class="m-r-5" [ngModel]="selectedCellValueType" value="1" (onClick)="selectCellValueType(1)"></p-radioButton>
    <p-radioButton label="Dollars" name="radDollars" class="m-r-5" [ngModel]="selectedCellValueType" value="2" (onClick)="selectCellValueType(2)"></p-radioButton>
    <p-radioButton label="Shares" name="radShares" class="m-r-5" [ngModel]="selectedCellValueType" value="3" (onClick)="selectCellValueType(3)"></p-radioButton>
  </span>
</div>
<div *ngIf="isModelToleranceForExcludedFF && (!!modelToleranceData.model || showModelToleranceNoModel)" class="flex justify-space-between margin-top-10">
  <div >
    <strong>Excluded Cash Target: </strong>
    <span class="text-info">{{excludedCashTarget}}</span>
  </div>
  <div >
    <strong>Excluded Cash Amount: </strong>
    <span class="text-info">{{excludedCashActual}}</span>
  </div>
</div>
<div class="row" *ngIf="modelToleranceData.model != undefined || showModelToleranceNoModel">
  <div class="col-md-12">
    <div class="responsive-table margin-top-10" *ngIf="!isModelToleranceUpdatesFF || selectedTab !== SECURITY_TAB">
      <ag-grid-angular #agGrid style="width: 100%; height: 220px;" class="ag-theme-alpine" (gridReady)="onGridReady($event)"
                       [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridRowData" rowSelection="single" [context]="gridContext"
                       suppressContextMenu="true">
      </ag-grid-angular>
    </div>
    <div class="responsive-table margin-top-10" *ngIf="isModelToleranceUpdatesFF && selectedTab === SECURITY_TAB">
      <ag-grid-angular #agGrid style="width: 100%; height: 220px;" class="ag-theme-alpine" (gridReady)="onGridReady($event)"
                       [getRowId]="getRowId" [context]="gridContext"
                       [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridRowData" rowSelection="single"
        [treeData]="true" [getDataPath]="getDataPath"
        suppressContextMenu="true">
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="!isTexpPortfolioRowModelToleranceFF && !!modelToleranceData?.account?.accountId && !modelToleranceData.currentModelId && !showModelToleranceNoModel">
  <span class="text-white">There is no model assigned to this account.</span>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="!isTexpPortfolioRowModelToleranceFF && !modelToleranceData?.account?.accountId">
  <span class="text-white">Please select an account from list.</span>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="isTexpPortfolioRowModelToleranceFF && !!modelToleranceData?.account?.accountId && !modelToleranceData.currentModelId && !isPortfolioTab && !showModelToleranceNoModel">
  <span class="text-white">There is no model assigned to this account.</span>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="isTexpPortfolioRowModelToleranceFF && !modelToleranceData?.account?.accountId && !isPortfolioTab">
  <span class="text-white">Please select an account from list.</span>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="isTexpPortfolioRowModelToleranceFF && modelToleranceData?.portfolio?.id && !modelToleranceData.currentModelId && isPortfolioTab && !showModelToleranceNoModel">
  <span class="text-white">There is no model assigned to this account/portfolio.</span>
</div>

<div class="choose-file-small-box file-upload-input-style"
  *ngIf="isTexpPortfolioRowModelToleranceFF && isPortfolioTab && !modelToleranceData?.portfolio?.id">
  <span class="text-white">Please select a portfolio/account from list</span>
</div>

